html {
  scroll-behavior: smooth;
}
body {
    letter-spacing: 0.03em;
    font-family: $font-primary;
    min-width: 320px;
    cursor: default;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  button {
    border: none;
    cursor: pointer;
  }
  
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }
  
  a {
    list-style: none;
    text-decoration: none;
  }
  
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

  .container{
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;  

    max-width: $mobile;
    @media screen and (min-width: $tablet){
      max-width: $tablet;
      padding: 0 35px;
    }
    @media screen and (min-width: $desktop){
      max-width: $desktop;
      padding: 0 115px;
    }
}
  
 
