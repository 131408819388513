.section-about {
    background-color: $second-section-color; 
    padding-top: 125px;   
    padding-bottom: 39px;
    position: relative;

    background-image: url("../images/milk-mobile.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 70% 90%;

        @media screen and (min-width: $tablet) {
            padding-top: 130px;
            padding-bottom: 66px;

            background-image: url("../images/milk-desktop.png");
            background-position: 70% 80%;          
        }
        @media screen and (min-width: $desktop) {
            padding-top: 125px;
            padding-bottom: 39px;

            background-image: url("../images/milk-desktop.png");        
            background-position: 70% 140%;           
    }

    &__lable {
        font-family: $font-secondary;
        color: $primary-header-color;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.17;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;
        cursor: default;        

        @media screen and (min-width: $tablet) {
            font-size: 26px;
            line-height: 1.15;
            margin-bottom: 16px;
          }
          @media screen and (min-width: $desktop) {
            font-size: 30px;
            line-height: 1.13;
            margin-bottom: 15px;

            &:hover {
                animation: springy-text; 
                animation-duration: 2s;
                animation-timing-function: ease-in-out;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-fill-mode: forwards;
                }
                @keyframes springy-text {
                0%   {
                    letter-spacing: 1.2em;
                }
                100%   {
                    letter-spacing: 0.1em;
                }
                }
          }
    }
    &__title {
        font-family: $font-secondary;
        color: $accent-color;
        font-size: 34px;
        font-weight: 400;
        line-height: 1.15;
        font-style: normal;
        align-items: center;
        letter-spacing: 0.04;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 26px;
        padding-left: 9px;  
        cursor: default;           
        
        @media screen and (min-width: $tablet) {
            font-size: 48px;
            line-height: 1.15;
            margin-bottom: 50px;
          }
          @media screen and (min-width: $desktop) {
            font-size: 58px;
            line-height: 1.14;
            margin-bottom: 55px;

            &:hover {
                animation: springy-text; 
                animation-duration: 2s;
                animation-timing-function: ease-in-out;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-fill-mode: forwards;
                }
                @keyframes springy-text {
                0%   {
                    letter-spacing: 0.7em;
                }
                100%   {
                    letter-spacing: 0.1em;
                }
                }
          }
    }
    &__main {
        @media screen and (min-width: $tablet) {
            display: flex;
          }
          @media screen and (min-width: $desktop) {
            display: flex;
          }
    }
    &__image-desktop {
        @media screen and (min-width: $tablet) {
            width: 340px;
            height: 339px;
            }
        @media screen and (min-width: $desktop) {
            width: 516px;
            height: 516px;            
        }
    }
    &__image {
        width: 280px;
        height: 279px;

        &:hover {
            animation-name: spin;
            animation-duration: 5s;
            animation-timing-function: linear;
            animation-iteration-count: 1;
                
            @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
          
            50% {
              transform: rotate(40deg);
            }        
            }
            }

        @media screen and (min-width: $tablet) {
        height: 340px;
        width: 339px;    
        }

        @media screen and (min-width: $desktop) {
        height: 516px;
        width: 516px;
        }
    }
    &__content {
        padding: 0 30px;      
        
        @media screen and (min-width: $tablet) {
            display: block;
            padding: 0; 
            margin-left: 17px; 
            width: 340px;    
            z-index: 1;       
          }
        @media screen and (min-width: $desktop) {
            display: block;
            padding: 51px 0 0 0;
            margin-left: 74px;
            width: 463px;
            z-index: 1;            
        }  
    }
    &__text-main {
        font-family: $font-primary;
        color: $secondary-text-color;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.58;
        margin-top: 46px;
        margin-bottom: 27px;
        cursor: default;
        transform: scale(1);
        transition: all linear 250ms;

        &:hover {
            transform: scale(1.2);            
            }
        
        @media screen and (min-width: $tablet) {
            font-size: 14px;
            line-height: 1.79;
            margin-top: 50px;
            margin-bottom: 19px;
        }
        @media screen and (min-width: $desktop) {
            font-size: 16px;
            line-height: 1.75;
            margin-top: 4px;
            margin-bottom: 35px;
        }
    }
    &__text-simple,
    &__text-additional {
        font-family: $font-primary;
        color: $primary-text-color;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.58;  
        cursor: default;  
        transform: scale(1);
        transition: all linear 250ms;

        &:hover {
            transform: scale(1.2);
            }
        
        @media screen and (min-width: $tablet) {
            font-size: 14px;
            line-height: 1.79;
        }

        @media screen and (min-width: $desktop) {
            font-size: 14px;
            line-height: 1.93;
        }
    }
    &__text-simple {        
        margin-bottom: 10px; 
        
        @media screen and (min-width: $tablet) {
            margin-bottom: 9px;
        }

        @media screen and (min-width: $desktop) {
            margin-bottom: 35px;
        }
    }    
    &__text-additional {          
        margin-bottom: 17px;    
        
        @media screen and (min-width: $tablet) {
            margin-bottom: 62px;
        }

        @media screen and (min-width: $desktop) {
            margin-bottom: 45px;
        }
    }
    
    &__button {
        position: relative;
        display: block;
        width: 176px;
        height: 44px;
        background-color: $button-white-color;
        border-radius: 22px;
        font-family: $font-primary;
        color: $accent-color;
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        line-height: 1.29;
        text-align: center;  
        cursor: pointer;

        &:hover,
        &:focus {
        background-color: #ECEBEB;
        transform: scale(1.2);
    }
        
        @media screen and (min-width: $tablet) {
            font-size: 16px;
            line-height: 1.31;
        }
    }
    &__icon {
        vertical-align: middle;
        margin-left: 13.6px;   
        border-radius: 2px;
        stroke: $accent-color;     
    }
}
