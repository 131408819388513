
@font-face {
	font-family: 'DM Sans';
	src: url('../fonts/DMSans-Bold.eot');
	src: local('☺'), url('../fonts/DMSans-Bold.woff') format('woff'), url('../fonts/DMSans-Bold.ttf') format('truetype'), url('../fonts/DMSans-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
    font-display: auto;
}

@font-face {
	font-family: 'DM Sans';
	src: url('../fonts/DMSans-Medium.eot');
	src: local('☺'), url('../fonts/DMSans-Medium.woff') format('woff'), url('../fonts/DMSans-Medium.ttf') format('truetype'), url('../fonts/DMSans-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
    font-display: auto;
}

@font-face {
	font-family: 'DM Sans';
	src: url('../fonts/DMSans-Regular.eot');
	src: local('☺'), url('../fonts/DMSans-Regular.woff') format('woff'), url('../fonts/DMSans-Regular.ttf') format('truetype'), url('../fonts/DMSans-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
    font-display: auto;
}

@font-face {
	font-family: 'Titan One';
	src: url('../fonts/TitanOne-Regular.eot');
	src: local('☺'), url('../fonts/TitanOne-Regular.woff') format('woff'), url('../fonts/TitanOne-Regular.ttf') format('truetype'), url('../fonts/TitanOne-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
    font-display: auto;
}


