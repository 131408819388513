//color
$header-base-color: #FFB8CA;
$header-circle-color: #FFA5BA;
$background-mobile-menu: #E17992;
$accent-color: #D41443;
$button-white-color: #FFFFFF;
$first-section-color: #E5E5E5;
$second-section-color: #FFF5F6;
$products-card-beige: #F0D1A5;
$products-card-green: #C2E297;
$primary-text-color: #907E82;
$secondary-text-color: #000000;
$background: #FAFAFA;
$border-footer-color: #E1E1E1;
$markers-reviews-color: #D9D9D9;
$background-reviews: #FAFAFA;
$primary-header-color: #FFA5BA;

//fonts
$font-primary: 'DM Sans', sans-serif;
$font-secondary: 'Titan One', sans-serif;

//screen breakpoints
$mobile: 480px;
$tablet: 768px; 
$desktop: 1280px;