$timing-function:              cubic-bezier(0.4, 0, 0.2, 1);
$trans-direct:                 250ms;

@mixin animation-efect ($efect) {
    transition: $efect $trans-direct $timing-function;
}
// Header + Abimation
header {
    background: linear-gradient(-45deg, #FFB8CA, #9F465B, #F6A7BB, #AF6678);
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
$bubble-count: 50;
  $sway-type: "sway-left-to-right", "sway-right-to-left";
  
  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }
  
  @function sample($list) {
    @return nth($list, random(length($list)));
  }
  
  .bubbles {
    position: absolute;
    width: 99vw;
    height: 501px;
    overflow: hidden;
    @media screen and (min-width: $tablet) {
    height: 398px;
    }
    @media screen and (min-width: $desktop) {
        height: 664px;
        }
  }
  
  .bubble {
    position: absolute;
    left: var(--bubble-left-offset);
    bottom: -75%;
    display: block;
    width: var(--bubble-radius);
    height: var(--bubble-radius);
    border-radius: 50%;
    animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;
  
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: hsla(183, 43%, 90%, 0.3);
      border-radius: inherit;
      animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
    }
  
    @for $i from 0 through $bubble-count {
      &:nth-child(#{$i}) {
        --bubble-left-offset: #{random_range(0vw, 100vw)};
        --bubble-radius: #{random_range(1vw, 10vw)};
        --bubble-float-duration: #{random_range(6s, 12s)};
        --bubble-sway-duration: #{random_range(4s, 6s)};
        --bubble-float-delay: #{random_range(0s, 4s)};
        --bubble-sway-delay: #{random_range(0s, 4s)};
        --bubble-sway-type: #{sample($sway-type)};
      }
    }
  }
  
  @keyframes float-up {
    to {
      transform: translateY(-175vh);
    }
  }
  
  @keyframes sway-left-to-right {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(100%);
    }
  }
  
  @keyframes sway-right-to-left {
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(-100%);
    }
  }


.container--modicator{
    height: 502px;
    position: relative;
    overflow: hidden;
    padding-top: 6px;
    border-bottom: 1px solid $background-mobile-menu;
    
    background-image:url(../images/hero-header/Ellipse768.png);
    background-size: 346px 346px;
    background-repeat:no-repeat;
    background-position: top 83px left 36px;


    @media screen and (min-width: $tablet){
        padding-top: 37px;
        height: 399px;
        border: none; 
        background-size: 322px 322px;
        background-position: bottom 46px right 137px;
    }

    @media screen and (min-width: $desktop) {
        height: 665px;
        background-size: 538px 538px;
        background-position: bottom 75px right 267px;
    }   
}

.header-holder__link{
    z-index: 10;
}

.header-holder{  
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 137px;

    @media screen and (min-width: $tablet){
        
        height: 50px;  
        margin-bottom:15px;
    }
    @media screen and (min-width: $desktop){
        align-items: center;
        margin-left: -70px;
        margin-bottom: 84px; 
    }
}

.logo-man {
    width: 47px;
    height: 47px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    top: 6px;
    left: 21px;
    @media screen and (min-width: $tablet){
    width: 47px;
    height: 47px;
    top: 36px;
    left: 38px;
    } 

    @media screen and (min-width: $desktop){
    left: 46px;
    } 
}

.buy.hiddenBtn{
    display: none;
 }

.menu-switch {
    display: flex;  
}

.buy{
    display: none;
    margin-right: 30px;
    z-index: 50;
    width: 160px;
    height: 44px;
    border-radius: 22px;
    
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;

    background-color: $button-white-color;
    color: $accent-color;

    @include animation-efect (transform);
    @include animation-efect (background-color);
    &:hover,
    :focus {
        background-color:#ECEBEB;
        transform: translateY(-3px);
    }

    @media screen and (min-width: $tablet){
        display: block;
    }

    @media screen and (min-width: $desktop){
     margin: auto;   
    }

    &__icon{
        margin-left: 21px;
        stroke: $accent-color;
    }

}
// Меню Навігації

.nav-tools {
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    pointer-events: none;

    flex-direction: column;
    height: 60px;
    padding-top: 60px;
    
    height: 100vh;
    width: 250px;
    background-color: $background-mobile-menu;
    z-index: 50;
    transform: translateX(100%);
    @include animation-efect (transform);
    @media screen and (min-width: $tablet){
        width: 254px;
    }  
    @media screen and (min-width: 1150px ){
        width:25vw;
    }  

    @media screen and (min-width: $desktop){
        position: static;
        transform: translateX(0);
        width: 541px;
        height: 50px;
        display: flex;
        flex-direction: row;
        pointer-events: visible;
        padding-top: 0;
        background: transparent;
    }    
}

.nav-tools.is-open {
    // position: absolute;
    pointer-events: auto;
    transform: translateX(0);
    height: 100vh;
    z-index: 500;
    
}

.navigation__list {
    display: block;

    @media screen and (min-width: $desktop){
        display: none;
        flex-direction: row;
        :not(:last-child) {
            margin-right: 35px;
        }  
    }  

    &--item {
        display: flex;
        align-items: center;
        height: 42px;
        padding-left: 21px;
        border: 1px solid #E18298;
        @media screen and (min-width: $tablet){
            padding-left: 24px;
        }
        @media screen and (min-width: $desktop){
            border: none;
            padding: 0;
        }
        
    }

    &--link {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.28;
        color: $button-white-color;
        cursor: pointer;

        @include animation-efect (color);

        @include animation-efect (transform);
        
        &:hover,
        :focus {
            transform: translateY(-2px);
            color: $accent-color;
        }
    }

    &--current{
        color: $accent-color;
    }
}

.burger{
    background-color: transparent;
    padding: 0;
    
    &__icon{
    fill: $accent-color;  
    stroke: $accent-color;  
    }

    @media screen and (min-width: $desktop){
        display: none;
    }
}

.nav-tools__button {
    width: 209px;
    height: 44px;
    
    background-color: $button-white-color;
    border-radius: 22px;
    
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 19px;

    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: $accent-color;
    @include animation-efect (background-color);
    @include animation-efect (transform);

    &:hover,
    :focus {
      transform: translateY(-2px);
      background-color:#ECEBEB;
    }

    @media screen and (min-width: $tablet){
        width: 186px;
    }

    @media screen and (min-width: $desktop){
        display: none;
    }

    
}

.icon-buy {
    margin-left: 27px;
    stroke: $accent-color;
    @media screen and (min-width: $tablet){
        margin-left: 21px;
    }
}

.nav-tools__close-button {
    background-color: transparent;
    padding: 0;
    @media screen and (min-width: $desktop){
       display: none;
    }
    .icon-close {
        stroke: $button-white-color;
    }

}
    
.js-close-menu {
    position: absolute;
    top: 23px;
    right:28px;
    background-color: transparent; 
    @media screen and (min-width: $tablet){
        right:34px;
    }
}

// Банер герой

.caption {
    position: relative;
    z-index: 5;
    @media screen and (min-width: $tablet){
        margin-bottom: 66px;
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: $desktop){
        margin-left: 0;
        margin-bottom: 112px;
    }
    
    &__title {
        font-family: $font-secondary;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $button-white-color;
        z-index: 10;
        margin-bottom: 19px;
        cursor: default;
        
        @media screen and (min-width: $tablet){
            font-size: 22px;
            line-height: 1.14;
        }
        @media screen and (min-width: $desktop){
            font-size: 38px;
            line-height: 1.16;
        }  
    }

    &__passion {
        color: $accent-color;
        font-size: 48px;
        line-height: 0.96;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        @media screen and (min-width: $tablet){
            font-size: 34px;
            line-height: 0.91;
        }
        @media screen and (min-width: $desktop){
            font-size: 58px;
            line-height: 0.91;
        }
    }    
}

.button-hold {
    display: flex;
}
.caption__button-link-one,
.caption__button-link-two {
    height: 24px;
    font-size: 8px;
    line-height: 1.25;

    @media screen and (min-width: $desktop){
        height: 40px;
        font-size: 14px;
        line-height: 1.28;
    } 
    
}


.caption__button-link {
    color: $accent-color;
}

.caption__button-one,
.caption__button-two{
    border-radius: 20px;
    padding: 0 14px;
    margin: 0;
    cursor: pointer;
    height: 24px;
    font-size: 8px;
    line-height: 1.25;

    @media screen and (min-width: $desktop){
        height: 40px;
        font-size: 14px;
        line-height: 1.28;
    } 
}

.caption__button-one {
    background-color: $accent-color;
    margin-right: 10px;
    width: 62px;
    @include animation-efect (background-color);
    

    @media screen and (min-width: $desktop){
        width: 102px;
    }
    &:hover,
    :focus {
        background-color: #C7133E;
        transform: translateY(-3px);
        @include animation-efect (transform);
    }

    &-link{
        color: $button-white-color;
    }
}

.caption__button-two {
    background-color:$button-white-color ;
    width: 84px;
    color: $accent-color;
    @include animation-efect (background-color);
    @media screen and (min-width: $desktop){
        width: 138px;
    }
    &:hover,
    :focus {
        background-color: #ECEBEB;
        transform: translateY(-3px);
        @include animation-efect (transform);
    }

    &-link{
    color: $accent-color;
    }
}

// Секція счетчика
.counter-sale {
    display: none;
    
    @media screen and (min-width: $tablet){
        display: block;
        height: 103px;
        text-align: center;
    }
    


    &__item {
        display: flex;
        flex-direction: column;
        align-items:center;
        width: 44px;
        height: 44px;
        margin-left: auto;
        margin-right: auto;
  
        font-family: $font-secondary;
        font-size: 28px;
        line-height: 1.15;
        color: $accent-color;
        
        @media screen and (min-width: $desktop){
            width: 76px;
            height: 77px;
            font-size: 46px;
            line-height: 1.15;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        background-image: url(../images/hero-header/white_circle.png);
        background-size: 16px 16px;
        background-repeat:no-repeat;
        background-position: top right;
        @media screen and (min-width: $desktop){
        background-size: 30px 30px;
        }
    }

    .counter-sale__item--size {
        width: 51px;
        @media screen and (min-width: $desktop){
            width: 92px;
        }
    }

    .counter-sale__span {
        font-family: $font-primary;
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 1.25;
        color: $button-white-color;
        @media screen and (min-width: $desktop){
            font-size: 14px;
            line-height: 1.29; 
        }
    }    
}
// Картинки на герої

.icon-circle {
    z-index: -5;
    position: absolute; 
    right: -62px;
    bottom: 75px;
    z-index: 0; 
    fill: $header-circle-color;

    @media screen and (min-width: $tablet){
        width: 324px;
        height: 322px;
        right: 137px;
        bottom: 46px;
    } 
    @media screen and (min-width: $desktop){
        width: 538px;
        height: 538px;
        right: 267px;
        bottom: 75px;
    } 
}

.hero__link--icecream {
    width: 171px;
    height: 410px;
    position: absolute; 
    right: 32px;
    bottom:0;
    z-index: 3;  
    cursor: pointer;
    display: block;

    @media screen and (min-width: $tablet){
        width: 143px;
        height: 336px;
        right: 247px;
    }

    @media screen and (min-width: $desktop){
        width: 236px;
        height: 557px;
        right: 450px;
        bottom:-17px;
    }
}

.hero__link--sootasty {
    
    &::before {
        content: '';
        display: none;

        @media screen and (min-width: $tablet){
            display: block;
            position: absolute;
            bottom: 0;
            right: 34px;
            
            z-index: 30;

            width: 168px;
            height: 133px;
            background-image: url(../images/hero-header/tasty768.png);
            background-repeat: no-repeat;
            background-size: cover;


            @media screen and(min-device-pixel-ratio:2),
            screen and(-webkit-min-device-pixel-ratio:2),
            screen and(-o-min-device-pixel-ratio:2/1),
            screen and(min-resolution:192dpi),
            screen and(min-resolution:2dppx) {
            background-image: url(../images/hero-header/tasty768@2x.png);
            }
        }
    
        @media screen and (min-width: $desktop){
            width: 293px;
            height: 232px; 
            right: 107px; 
            background-image: url(../images/hero-header/tasty1280.png);

            @media screen and(min-device-pixel-ratio:2),
            screen and(-webkit-min-device-pixel-ratio:2),
            screen and(-o-min-device-pixel-ratio:2/1),
            screen and(min-resolution:192dpi),
            screen and(min-resolution:2dppx) {
            background-image: url(../images/hero-header/tasty1280@2x.png);
            }

        }
    }

}

.big-eco {
    margin-left: 120px;
    width: 114px;
    height: 73px;
    font-size: 8px;
    line-height: 1.5;
    letter-spacing: 0;
    color: $button-white-color;
    position: relative;
    display: none;

    @media screen and (min-width: $tablet){
        display: block;
    }

    @media screen and (min-width: $desktop){
        width: 200px;
        height: 124px;
        margin-left: 180px;
        font-size: 14px;
        line-height:1.42
    }


    &__picture {

        &::before {
            content: '';
            display: none;
    
            @media screen and (min-width: $tablet){
                display: block;
                position: absolute;
                bottom: -5px;
                left: -120px;
                
                z-index: 30;
    
                width: 124px;
                height: 126px;
                background-image: url(../images/hero-header/milk768.png);
                background-repeat: no-repeat;
                background-size: cover;
    
    
                @media screen and(min-device-pixel-ratio:2),
                screen and(-webkit-min-device-pixel-ratio:2),
                screen and(-o-min-device-pixel-ratio:2/1),
                screen and(min-resolution:192dpi),
                screen and(min-resolution:2dppx) {
                background-image: url(../images/hero-header/milk768@2x.png);
                }
            }
        
            @media screen and (min-width: $desktop){
                width: 188px;
                height: 191px; 
                left: -210px; 
                bottom: -5px;
                background-image: url(../images/hero-header/milk1280.png);
    
                @media screen and(min-device-pixel-ratio:2),
                screen and(-webkit-min-device-pixel-ratio:2),
                screen and(-o-min-device-pixel-ratio:2/1),
                screen and(min-resolution:192dpi),
                screen and(min-resolution:2dppx) {
                background-image: url(../images/hero-header/milk1280@2x.png);
                }
    
            }
        }

    }   
}

.protuct-milk {
    margin-left: 120px;
    width: 114px;
    height: 73px;
    font-size: 8px;
    line-height: 1.5;
    letter-spacing: 0;
    color: $button-white-color;
    position: relative;
    
    
    @media screen and (min-width: $tablet){
        margin-left: 0;
    }
    @media screen and (min-width: $desktop){
        width: 200px;
        height: 78px;
        font-size: 14px;
        line-height: 1.43; 
    }
    &__text {
        cursor: default;
    }
}

.button-big-eco {
    margin-top: 7px;
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $button-white-color;
    transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-property: all;

    
    &:hover .button-big-eco__icon {
        transform: rotate(90deg);
        width: 22px;
        height: 22px;
        margin-top: -2px;
        background-color: $accent-color;
        border-radius: 50%;
        fill: $button-white-color;
        
        @media screen and (min-width: $desktop){
            width: 32px;
            height: 32px;
            margin-top: -3px;
            
        }
    }

    @media screen and (min-width: $desktop){
        width: 26px;
        height: 26px;
    }

    &__icon {
        fill: $accent-color;
        border-radius: 50%;
        transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition-property: all;
        
        
        
        @media screen and (min-width: $desktop){
            width: 25px;
            height: 25px;
        }
    }   
}

.navigation__desktop {
    display: none;
    opacity: 0;
    @include animation-efect (opacity);

    @media screen and (min-width: $desktop){
        position: relative;
        top: 0;
        opacity: 1;
        display: flex;
        flex-direction: row;
        :not(:last-child) {
            margin-right: 40px;
        }  
    }

    &--item {
        height: 42px;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
    }
        
    

    &--link {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.28;
        color: $button-white-color;
        cursor: pointer;

        @include animation-efect (background-color);

        @include animation-efect (transform);
        
        &:hover,
        :focus {
            transform: translateY(-2px);
            color: $accent-color;
        }
    }

    &--current{
        color: $accent-color;
    }


}