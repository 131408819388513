.product {
  padding-top: 121px;
  padding-bottom: 98px;
  background-color: $button-white-color;

  @media screen and (min-width: $tablet) {
    padding-top: 122px;
    padding-bottom: 112px;
  }
  @media screen and (min-width: $desktop) {
    padding-top: 127px;
    padding-bottom: 119px;
  }
}

.product__header-label {
  margin-bottom: 15px;

  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.17;

  text-align: center;
  text-transform: uppercase;

  color: $primary-header-color;
  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: letter-spacing;

  &:hover {
    animation: springy-text;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @media screen and (min-width: $tablet) {
    font-size: 26px;
    line-height: 1.15;
  }
  @media screen and (min-width: $desktop) {
    font-size: 30px;

    @keyframes springy-text {
      0% {
        letter-spacing: 0.7em;
      }
      100% {
        letter-spacing: 0.1em;
      }
    }
  }
}

.product__header {
  margin-bottom: 144px;

  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 1.15;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: $accent-color;
  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: letter-spacing;

  &:hover {
    animation: springy-text;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @media screen and (min-width: $tablet) {
    margin-bottom: 150px;
    font-size: 48px;
  }
  @media screen and (min-width: $desktop) {
    margin-bottom: 259px;
    font-size: 58px;

    @keyframes springy-text {
      0% {
        letter-spacing: 0.7em;
      }
      100% {
        letter-spacing: 0.1em;
      }
    }
  }
}

.product__list {
  @media screen and (min-width: $tablet) {
    display: flex;
    justify-content: center;
    margin: -10px;
  }

  @media screen and (min-width: $desktop) {
    display: flex;
    justify-content: center;
    margin: -15px;
  }
}

.product__item {
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 40px;
  margin: 0 auto;
  width: 100%;
  height: 485px;
  border-radius: 24px;

  text-align: center;

  @media screen and (max-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 130px;
    }
  }

  &:nth-child(1) {
    background-color: $header-base-color;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -113px;
      transform: translateX(-50%);

      z-index: 60;

      width: 249px;
      height: 252px;

      background-image: url(../images/product1-320.png);
      background-size: 249px 252px;

      @media screen and(min-device-pixel-ratio:2),
        screen and(-webkit-min-device-pixel-ratio:2),
        screen and(-o-min-device-pixel-ratio:2/1),
        screen and(min-resolution:192dpi),
        screen and(min-resolution:2dppx) {
        background-image: url(../images/product1-320@2x.png);
      }
    }
  }

  &:nth-child(2) {
    background-color: $products-card-beige;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -113px;
      transform: translateX(-50%);

      z-index: 60;

      width: 249px;
      height: 252px;

      background-image: url(../images/product2-320.png);
      background-size: 249px 252px;

      @media screen and(min-device-pixel-ratio:2),
        screen and(-webkit-min-device-pixel-ratio:2),
        screen and(-o-min-device-pixel-ratio:2/1),
        screen and(min-resolution:192dpi),
        screen and(min-resolution:2dppx) {
        background-image: url(../images/product2-320@2x.png);
      }
    }
  }

  &:nth-child(3) {
    background-color: $products-card-green;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -113px;
      transform: translateX(-50%);

      z-index: 60;

      width: 249px;
      height: 252px;

      background-image: url(../images/product3-320.png);
      background-size: 249px 252px;

      @media screen and(min-device-pixel-ratio:2),
        screen and(-webkit-min-device-pixel-ratio:2),
        screen and(-o-min-device-pixel-ratio:2/1),
        screen and(min-resolution:192dpi),
        screen and(min-resolution:2dppx) {
        background-image: url(../images/product3-320@2x.png);
      }
    }
  }

  @media screen and (min-width: $tablet) {
    flex-basis: calc((100% - 10px * 6) / 3);
    margin: 10px;
    padding: 40px 11px;

    height: 485px;

    &:nth-child(1) {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -102px;
        transform: translateX(-50%);

        z-index: 60;

        width: 220px;
        height: 224px;

        background-image: url(../images/product1-768.png);
        background-size: 220px 224px;

        @media screen and(min-device-pixel-ratio:2),
          screen and(-webkit-min-device-pixel-ratio:2),
          screen and(-o-min-device-pixel-ratio:2/1),
          screen and(min-resolution:192dpi),
          screen and(min-resolution:2dppx) {
          background-image: url(../images/product1-768@2x.png);
        }
      }
    }

    &:nth-child(2) {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -102px;
        transform: translateX(-50%);

        z-index: 60;

        width: 220px;
        height: 224px;

        background-image: url(../images/product2-768.png);
        background-size: 220px 224px;

        @media screen and(min-device-pixel-ratio:2),
          screen and(-webkit-min-device-pixel-ratio:2),
          screen and(-o-min-device-pixel-ratio:2/1),
          screen and(min-resolution:192dpi),
          screen and(min-resolution:2dppx) {
          background-image: url(../images/product2-768@2x.png);
        }
      }
    }

    &:nth-child(3) {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -102px;
        transform: translateX(-50%);

        z-index: 60;

        width: 220px;
        height: 224px;

        background-image: url(../images/product3-768.png);
        background-size: 220px 224px;

        @media screen and(min-device-pixel-ratio:2),
          screen and(-webkit-min-device-pixel-ratio:2),
          screen and(-o-min-device-pixel-ratio:2/1),
          screen and(min-resolution:192dpi),
          screen and(min-resolution:2dppx) {
          background-image: url(../images/product3-768@2x.png);
        }
      }
    }
  }

  @media screen and (min-width: $desktop) {
    flex-basis: calc((100% - 15px * 6) / 3);
    margin: 15px;
    padding: 50px 44px;

    max-width: 330px;
    height: 525px;

    &:nth-child(1) {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -210px;
        transform: translateX(-50%);

        z-index: 60;

        width: 335px;
        height: 335px;

        background-image: url(../images/product1-1200.png);
        background-size: 335px 335px;

        @media screen and(min-device-pixel-ratio:2),
          screen and(-webkit-min-device-pixel-ratio:2),
          screen and(-o-min-device-pixel-ratio:2/1),
          screen and(min-resolution:192dpi),
          screen and(min-resolution:2dppx) {
          background-image: url(../images/product1-1200@2x.png);
        }
      }
    }

    &:nth-child(2) {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -210px;
        transform: translateX(-50%);

        z-index: 60;

        width: 335px;
        height: 335px;

        background-image: url(../images/product2-1200.png);
        background-size: 335px 335px;

        @media screen and(min-device-pixel-ratio:2),
          screen and(-webkit-min-device-pixel-ratio:2),
          screen and(-o-min-device-pixel-ratio:2/1),
          screen and(min-resolution:192dpi),
          screen and(min-resolution:2dppx) {
          background-image: url(../images/product2-1200@2x.png);
        }
      }
    }

    &:nth-child(3) {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -210px;
        transform: translateX(-50%);

        z-index: 60;

        width: 335px;
        height: 335px;

        background-image: url(../images/product3-1200.png);
        background-size: 335px 335px;

        @media screen and(min-device-pixel-ratio:2),
          screen and(-webkit-min-device-pixel-ratio:2),
          screen and(-o-min-device-pixel-ratio:2/1),
          screen and(min-resolution:192dpi),
          screen and(min-resolution:2dppx) {
          background-image: url(../images/product3-1200@2x.png);
        }
      }
    }
  }

  //overlay
  &:hover .product__overlay {
    cursor: pointer;
    opacity: 1;
    box-shadow: 0px 5px 15px rgba(144, 35, 62, 0.632);
  }

  &:hover .icon-arrow-right {
    transform: rotate(-90deg);
    width: 50px;
    height: 50px;
    margin-top: -10px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    background-color: $accent-color;
    border-radius: 35%;
    fill: $button-white-color;
    cursor: pointer;
  }

  &:hover .product__description {
    opacity: 0;
  }

  &:hover .product__name {
    z-index: 60;
    font-size: 22px;
    cursor: pointer;
    @media screen and (min-width: $desktop) {
      font-size: 33px;
    }
  }
  &:hover .marker {
    z-index: 60;
  }
}

.product__name {
  margin-top: auto;

  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.15;

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: $button-white-color;

  @media screen and (min-width: $desktop) {
    font-size: 30px;
  }

  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: z-index, font-size;
}

.product__description {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.52;

  text-align: center;
  letter-spacing: 0.04em;

  color: $button-white-color;

  @media screen and (max-width: 768px) {
    width: 200px;
  }

  @media screen and (min-width: $tablet) {
    margin-bottom: 30px;

    line-height: 1.76;
  }

  @media screen and (min-width: $desktop) {
    margin-bottom: 62px;
    font-size: 16px;
  }

  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: opacity;
}

.icon-arrow-right {
  margin: 0 auto;
  background-color: $button-white-color;
  border-radius: 50%;
  fill: $accent-color;
  z-index: 55;

  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: width, height, margin-top, transform, box-shadow, background-color, fill;
}

//Маркер - три крапки

.marker {
  position: relative;
  margin: 30px auto;
  width: 6px;
  height: 6px;
  background-color: $button-white-color;
  border-radius: 50%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: inherit;
  }

  &:before {
    right: 10px;
    top: 0px;
  }

  &:after {
    left: 10px;
    top: 0px;
  }

  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: z-index;
}

//overlay

.product__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(91, 92, 94, 0.15);
  border-radius: 24px;

  z-index: 50;

  opacity: 0;

  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: opacity, box-shadow;
}

.product__text {
  position: absolute;
  width: 90%;
  height: initial;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 16px;
  line-height: 1.56;

  color: $button-white-color;

  @media screen and (max-width: 767px) {
    width: 220px;
  }

  @media screen and (min-width: $tablet) {
    font-size: 15px;
  }

  @media screen and (min-width: $desktop) {
    top: 60%;
    font-size: 18px;
    font-weight: 700;
  }
}
