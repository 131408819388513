.footer {
    &__container {
        margin-bottom: 43px;
        @media screen and (min-width: $tablet){
            margin-bottom: 91px;
            display: flex;
        }
        @media screen and (min-width: $desktop){
            margin-bottom: 97px;
        }
    }

    position: relative;
    background-color: $second-section-color;
    min-width: 320px;

    &__text-box {
        width: 232px;

        @media screen and (min-width: $tablet){
            width: 340px;
        }
        @media screen and (min-width: $desktop){
            width: 480px;
            
            &:hover {
               .footer__title--made {
                   color: $accent-color;
                   transform: translate(-170px, 27px);
               }
               .footer__title--ice-cream {
                   transform: translate(55px, 0px);
               }
            }
        }
    }

    &__title{
        cursor: default;
        text-align: left;
        font-family: $font-secondary;
        font-weight: 400;
        text-transform: uppercase;
        @extend %anim-all;
        &--one {
            font-size: 18px;
            line-height: 1.167;
            color: $primary-header-color;
            margin-bottom: 17px;

            @media screen and (min-width: $tablet){
                margin-top: 7px;
                margin-bottom: 16px;
                font-size: 26px;
                line-height: 1.154;
                letter-spacing: 0.04em;
            }
            @media screen and (min-width: $desktop){
                margin-top: 0px;
                margin-bottom: 15px;
                font-size: 30px;
                line-height: 1.133;
            }
        }
    
        &--two {
            font-size: 34px;
            line-height: 1.147;
            letter-spacing: 0.04em;
            color: $accent-color;
            margin-bottom: 43px;
            display: flex;
            flex-direction: column;
            
            @media screen and (min-width: $tablet){
                font-size: 48px;
                line-height: 1.146;
                letter-spacing: 0.06em;
                margin-bottom: 0px;
            }
            @media screen and (min-width: $desktop){
                font-size: 58px;
                line-height: 1.138;
                letter-spacing: 0.06em;
                flex-direction: row;
            }
        }

        &--word {
            display: flex;
        }

        &--letter {
            @media screen and (min-width: $tablet){
                &:hover {
                    transform: scale(1.2); 
                }
            }

            &-spase {
                @media screen and (min-width: $desktop){
                    margin-right: 17px;
                    
                }
            }
            &-push {
                margin-right: -2px;
            }
        }
        &--made,
        &--ice-cream,
        &--letter {
            display: inline-block;
            @extend %anim-all;
        }
    }
}

.social-box {
    margin-bottom: 46px;
    @media screen and (min-width: $desktop){
        margin-bottom: 50px;
        margin-right: 5px;
    }
    
    &__list {
       &:not(:first-child) {
            margin-left: 28px;
            @media screen and (min-width: $tablet){
                margin-left: 30px;
            }
            @media screen and (min-width: $desktop){
                margin-left: 25px;
            }
       }
    }
    &__links {
        display: flex;
        justify-content: flex-end;
    }
    &__icon {
       display: block;
       fill: $primary-header-color;
       @extend %anim-fill;

       .social-box__link:hover &,
       .social-box__link:focus & {
           fill: $accent-color;
       }
    }
}

.contact-box {
    font-weight: 700;
    text-align: right;
    font-size: 12px;
    line-height: 1.86;
    cursor: default;

    @media screen and (min-width: $tablet){
        font-size: 14px;
    }
    @media screen and (min-width: $desktop){
        font-size: 16px;
        letter-spacing: 0.02em;
    }

    &__phone {
        display: inline-block;
        color: $accent-color;
        @extend %anim-transform;
        &:focus,
        .contact-box:hover &{
            transform: scale(1.2) translateX(12px);
        }
    }
}

.copyright-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border-top: 1px solid $border-footer-color;
    padding-top: 22px;
    padding-bottom: 25px;

    @media screen and (min-width: $tablet){
        padding-top: 29px;
        padding-bottom: 18px;
    }
    @media screen and (min-width: $desktop){
        padding-top: 9px;
        padding-bottom: 10px;
    }

    &__text {
        color: $primary-text-color;
        font-weight: 700;
        text-align: center;
        max-width: 150px;
        font-size: 14px;
        line-height: 1.52;
        @extend %anim-transform;

        @media screen and (min-width: $tablet){
            line-height: 1.86;
            max-width: 276px;
            letter-spacing: 0.02em;
        }

        &:hover {
          transform: scale(1.3);
        }
    }
}

@media screen and (min-width: $tablet){
     .contacts-box {
        margin-left: auto;
    }
}
  /*Animation variables*/

  $anim-func: cubic-bezier(0.4, 0, 0.2, 1);
  $anim-time: 500ms;

  /*Animation placeholders*/

  %anim-all {
    transition: all $anim-time $anim-func;
  }

  %anim-transform {
    transition: transform $anim-time $anim-func, color $anim-time $anim-func;
  }
  
  %anim-fill {
    transition: fill $anim-time $anim-func;
  }
