.modal-buy {
  position: relative;
  display: none;
  max-height: 100vh;
  position: fixed;
  z-index: 100;
  // padding-top: 5%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 131, 207, 0.274);
  background-color: rgba(253, 131, 157, 0.377);
}

.modal-content {
  position: absolute;
  background-color: #e1bee7;
  max-height: 100vh;
  margin: auto;
  padding: 0;
  border: 3px solid $accent-color;
  border-radius: 10px;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(255, 22, 131, 0.356), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  background-color: $header-base-color;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.close {
  color: $accent-color;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: $button-white-color;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  color: $accent-color;
  font-family: $font-secondary;
  font-size: 30px;
  height: 60px;
  text-align: center;
}
@media screen and (max-width: $mobile) {
  .modal-header {
    padding: 2px 16px;
    color: $accent-color;
    font-family: $font-secondary;
    font-size: 15px;
    height: 30px;
    text-align: center;
  }
}
@media screen and (max-width: $tablet) {
  .modal-header {
    padding: 2px 16px;
    color: $accent-color;
    font-family: $font-secondary;
    font-size: 20px;
    height: 40px;
    text-align: center;
  }
}
.modal-body {
  padding: 2px 16px;
  display: flex;
  &__img {
    margin-left: auto;
    margin-right: auto;
    height: 80%;
    margin-bottom: 10px;
  }
  &__input {
    display: block;
    background-color: $second-section-color;
    width: 100px;
    border-radius: 5px;
    border: 1px solid $accent-color;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.modal-footer__date:focus {
  border: 2px solid $accent-color;
  transition-duration: 250ms;
}
.modal-footer {
  padding: 2px 16px;
  color: $accent-color;
  display: flex;
  flex-direction: column;
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
  &__date {
    background-color: $second-section-color;
    border-radius: 5px;
    border: 1px solid $accent-color;
  }
  &__btn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 160px;
    height: 44px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    background-color: $button-white-color;
    color: $accent-color;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &__btn:hover {
    background-color: #ecebeb;
    transform: translateY(-3px);
  }
  &__link {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
  }
}

.modal__date--comment {
  resize: none;
  height: 120px;
  padding: 12px 16px;
}
// .egg {
//   position: relative;
//   display: none;
//   max-height: 100vh;
//   position: fixed;
//   z-index: 100;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   background-color: rgba(0, 0, 0, 0.747);
//   background-color: rgba(0, 0, 0, 0.726);
//   &__content {
//     position: absolute;
//     max-height: 100vh;
//     margin: auto;
//     padding: 0;
//     border-radius: 10px;
//     max-width: 100vh;
//     border-radius: 5px;
//     transform: translate(-50%, -50%);
//     top: 50%;
//     left: 50%;
//   }
//   &__header {
//     padding: 2px 16px;
//     color: $accent-color;
//     font-family: $font-secondary;
//     font-size: 30px;
//     height: 60px;
//     text-align: center;
//   }
//   &__body {
//     max-height: 100vh;
//     max-width: 100vh;
//   }
// }
// .closeX {
//   color: $accent-color;
//   float: right;
//   font-size: 28px;
//   font-weight: bold;
// }

// .closeX:hover,
// .closeX:focus {
//   color: $button-white-color;
//   text-decoration: none;
//   cursor: pointer;
// }
