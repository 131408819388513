p{
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0; 
}

// Mixins
@mixin paddings($paddingL, $paddingR) {
    padding-left: $paddingL;
    padding-right: $paddingR; 
}
@mixin textStyles ($font-family, $letter-spacing, $line-height, $font-size ,$font-weight){
    font-family:$font-family;
    letter-spacing:$letter-spacing;
    line-height: $line-height;
    font-size:$font-size;
    font-weight:$font-weight;
}
@mixin marginesPaddings ($margin, $padding) {
    margin:$margin;
    padding: $padding;
}
.section-contacts__title.hidden{
    margin-top: -110px;
   display: flex;
}
// Section

.section-contacts{
    @include marginesPaddings( auto auto, 0 0 113px 0);
    
    @media screen and (min-width:$tablet) {
        @include marginesPaddings( auto auto, 0 0 165px 0);
    }
  
    background-color:$second-section-color;
    & > .container{
        @include marginesPaddings( auto auto, 0 50px 0 50px);
        @media screen and (min-width:$desktop) {
             @include marginesPaddings( auto auto, 0 115px 0 115px);
            }
        @media screen and (min-width:$desktop) {
            background-image: url(../images/sectionbg2.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            }
        } 
}

.section-contacts__item{ 
    background-color: white;
    padding: 43px 20px;
    border-radius: 24px;
    box-shadow: 0px 8px 30px rgba(212, 20, 67, 0.1);
    transition: all 300ms linear;
    display: flex;
    flex-direction: column;
    z-index: 20;
    &:hover{
        box-shadow: 0px 8px 30px rgba(144, 35, 62, 0.632);  
        cursor: pointer;
       }  
    &:not(:last-child){
        margin-bottom:20px ;
    }
    &:last-child{
        margin-bottom: 54px;
    }
    @media screen and(min-width:320px){
        margin: 0 auto 0 auto;  
        
    }

    @media screen and (min-width:768px) {
       
        @include marginesPaddings( -78px auto 0 auto, 50px 29px);
        &:last-child{
            margin-bottom: 0;
        }
        &:not(:last-child){
            margin-bottom:0px ;
            margin-right: 20px;
        }
      
    }
    @media screen and(min-width:$desktop){
         flex-basis: calc(100%/3);
         padding: 50px 43px 62px 43px;
         margin-top: -102px;
         &:last-child{
            margin-bottom: 0;
        }
        &:not(:last-child){
            margin-bottom:0px ;
            margin-right: 30px;
        }
        &:hover{
            box-shadow: 0px 8px 30px rgba(144, 35, 62, 0.632);  
            cursor: pointer;
           }  
    }

}
.section-contacts__cart-list{
    @media screen and (max-width:768px) {
        display: flex;
        flex-direction: column;  
    }
    
    @media screen and (min-width: $tablet) {
        display: flex;
        margin-bottom: 81px;
        flex-direction: row;
    }
}
.section-contacts__item-minus{
    @media screen and (max-width:768px) {
        display: flex;
        margin-top: -84px;
     } 
    }
  
.section-contacts__place{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 16px;
    margin-bottom: 25px;
    color:white;
    text-transform: uppercase;
    border-radius: 4px;
    @include textStyles($font-primary, 0.04em, -height, -size , -weight);
 
    &.one{
      background-color: $products-card-beige;  
    }
    &.two{
        background-color:$products-card-green;
        width: 130px;  
    }
    &.three{
        background-color:$products-card-beige;
    }
    @media screen and (min-width:$tablet){
        display: flex;
        width: 73px;
        height: 28px;
        line-height: 186%;
        justify-content: center;
        align-items: center;       
    }

}
.section-contacts__subtitle{   
    @include textStyles($font-primary, -spacing, 18px, 12px , 500);
    color: black;
    margin-bottom: 10px;
    @media screen and (min-width:$tablet){
        margin-bottom: 14px;
        font-size: 14px;
        
    }
    @media screen and (min-width:$desktop){
        margin-bottom: 10px;
        @include textStyles($font-primary, -spacing, 183%, 16px , -weight)
    }
    
}
.section-contacts__subtitle.day{
     margin-bottom: 0px;
}
.section-contacts__text{
    @include textStyles($font-primary, -spacing, 23px, 12px , -weight);
    color: $primary-text-color;
    padding-bottom: 25px;
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 25px;
    @media screen and (min-width:$tablet){
        margin-bottom: 32px;
        @include textStyles($font-primary, -spacing, 23px, 14px , -weight);
        
    }
    @media screen and (min-width:$desktop){
        margin-bottom: 32px;
        @include textStyles($font-primary, -spacing, 183%, 16px , -weight);
       
    }
}
.section-contact__time-information{
    @include textStyles($font-primary, -spacing, 186%, 12px , 500);   
    color: $primary-header-color;
    margin-bottom: 10px;
    @media screen and (min-width:$tablet){
        margin-bottom: 16px;
        font-size: 13.9px;
       
    }
    @media screen and (min-width:$desktop){
        margin-bottom: 15px;
        @include textStyles($font-primary, -spacing, 183%, 16px, -weight)
    }
}
.section-contacts__schedule{
    padding-bottom: 25px;
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 25px;
    @media screen and (min-width:$desktop){
        margin-bottom: 32px;
    }
}
.section-contacts__contact{
   
    @include textStyles($font-primary, -spacing, 18px, 12px, 500);
    
    color: $primary-text-color; 
    @media screen and (min-width:$tablet) {
        display: flex;
        flex-direction: column;
        font-size: 14px;
    }
    @media screen and (min-width:$desktop) {
        display: flex;
        flex-direction: column;
        font-size: 16px;
     
    }
    &:not(:last-child){
        margin-bottom: 10px;
    }
   
}
.section-contact__btn{
@include textStyles($font-primary, -spacing, 21px, 16px, bold);
width: 161.45px;
height: 44px;
border-radius: 22px;
transition: linear all 250ms;
    &.one{
        background: rgba(212, 19, 66, 1);
        color: white;
        margin-bottom: 10px;
       
        @media screen and(min-width:$tablet) {
            margin-right: 15px;
            width:174px ;
            &:hover{
                transform: translateY(-10px);
            }
        }  
        @media screen and (min-width:$desktop) {
            width: 180px; 
            &:hover{
                transform: translateY(-10px);
            }
        }
    }
    &.two{
        background:white;
        color: $accent-color;
     
    }
    @media screen and (min-width:$tablet) {
        width: 191.45px; 
        &:hover{
            transform: translateY(-10px);
        }
    }
    @media screen and (min-width:$desktop) {
        width: 198px; 
        &:hover{
            transform: translateY(-10px);
        }
    }

}
.section-contact__btn-list{
    text-align: center;
    @media screen and(min-width:$tablet) {
       display: flex; 
       justify-content: center;
    }
}
.btn-icon{
    width: 10px;
    height: 10px;
    margin-left: 13px;
    &.one{
        stroke: white;
        @media screen and (min-width:320px) and (max-width:$tablet){
        margin-left: 8px;  
        }
    }
    &.two{
        stroke:$accent-color;
    }
}

// Modal map

.backdrop {
    position: fixed;
    z-index: 99;
    visibility: visible;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 93px 15px;
    background-color: rgba(0, 0, 0, 0.2);
  
    opacity: 1;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
      visibility 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .backdrop.is-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    //   transform: scale(1);
  }
  .modal{
    position: relative;
    text-align: center;
   
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    max-width: 450px;
    height: 610px;
    border: 3px solid  #FFB8CA;
    background-color: #fff;
    border-radius: 4px;
    padding: 40px;
    @media screen and (max-width:465px) {
        box-sizing: content-box;  
    }
  }
  .modal__close-btn{
    position: absolute;
    top: 8px;
    right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    

    max-width: 30px;
    max-height: 30px;

    border: 1px solid #FFB8CA;
    border-radius: 50%;
    background-color: #FFF;
    transition: all linear 250ms;
    &:hover{
        background-color: #FFB8CA;
    }
  }  
 .modal__icon-close{
     stroke: #FFB8CA;
     &:hover{
         stroke: white;
     }
 }
.modal__title {
    text-align: center;
    @include textStyles($font-primary, 0.03em, -height, 25px, bold);
   
    color: #D41443;
    margin-bottom: 12px;
  }
  .modal__map{
      display: flex;
      justify-content: center;
      border: 2px solid  #FFB8CA;
      border-radius: 4px;
      max-width: 370px;
      height: 410px;
      margin-bottom: 20px;
      
  }
  .modal__adress{
    color:#D41443;
    margin-bottom: 15px;
    font-size: 17px;
  }
  .modal_map-btn{
    @include textStyles($font-primary, -spacing, 21px, 16px, bold);
    margin: 0 auto 0 auto ;
    max-width: 200px;
    height: 44px;
    border-radius: 22px;
    background-color: #fff;
    border: 2px solid  #FFB8CA;
    padding: 0 15px 0 15px;
    color: #FFB8CA;
    transform: scale(1);
    transition: linear 250ms all;
    &:hover{
        transform: scale(1.25);
        background-color: #FFB8CA; 
      &>.modal_btn-text{
          color: white;
      }
    }
  }
  .modal_btn-text{
    color: #D41443;
  }

  .modal__google-maps{
    width: 100%;
    height: 100%;       
  }



//   modal

.modal-fr {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 450px;
    max-height: 610px;
    border-radius: 4px;
    border: 3px solid  #FFB8CA;
    padding: 40px;
  
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.2);
  
    &__close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      width: 30px;
      height: 30px;
  
      border: 1px solid #FFB8CA;
      border-radius: 50%;
      background-color: white;
      stroke: #FFB8CA;
      &:hover{
        stroke: white; 
        background-color: #FFB8CA;
      }
    }
  
    &__title {
      text-align: center;
        @include textStyles($font-primary, 0.03em, -height, 20px, bold);
      color: #D41443;
      margin-bottom: 12px;
    }
    &__send-btn {
        @include textStyles($font-primary, -spacing, 21px, 16px, bold);
   
    max-width: 200px;
    height: 44px;
    border-radius: 22px;
    background-color: #fff;
    border: 2px solid  #FFB8CA;
    color: #D41443;
    transform: scale(1);
    transition: linear 250ms all;
    padding: 0 20px 0 20px;
    &:hover{
        transform: scale(1.25);
        background-color: #FFB8CA; 
        color: white;
      
    }
    }
  }
  
  .modal-fr__close-btn:hover .modal__icon-close {
    fill: #FFB8CA;
    cursor: pointer;
  }
  
  .modal-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    &__input {
      height: 40px;
      width: 100%;
      padding-left: 40px;
    }
    &__input,
    &__textarea {
      border: 1px solid;
      border-color: rgba(33, 33, 33, 0.2);
      border-radius: 4px;
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    &__textarea {
      padding: 12px 16px;
      height: 120px;
      resize: none;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: rgba(117, 117, 117, 0.5);
      margin-bottom: 20px;
      &:not(:placeholder-shown) {
        color: black;
      }
    }
    &__label,
    &__textarea-label {
      margin-bottom: 4px;
  
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: #757575;
    }
    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
  
      width: 18px;
      height: 18px;
    }
  }
  .modal-inputs:focus-within .modal-inputs__input,
  .modal-inputs:focus-within .modal-inputs__textarea {
    outline: none;
    border-color: #FFB8CA;
  }
  .modal-inputs:focus-within .modal-inputs__icon {
    fill: #FFB8CA;
  }
  .modal-inputs:hover .modal-inputs__input,
  .modal-inputs:hover .modal-inputs__textarea {
    outline: none;
    border-color:#FFB8CA;
  }
  
  .input-wrraper {
    position: relative;
  }
    
  .send-btn-wrapper {
    width: 100%;
    text-align: center;
  }

 